import { StylesProvider } from '@material-ui/styles';
import React, { ChangeEvent, FC, useEffect } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import { LargeText, MediumText } from './Font';

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? '100%' : '')};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;

  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const InputLabelRemark = styled(LargeText)`
  padding-left: 0.5em;

  color: red;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
`;

const InputError = styled(MediumText)`
  height: 18px;
  font-size: 18px !important;
  color: ${constants.RED};
  white-space: nowrap;
`;

const InputErrorText = styled(MediumText)`
  height: 24px;
  color: ${constants.RED};
  margin-top: -1em;
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

interface IInputTemplateProps {
  label?: string;
  className?: string;
  error?: string;
  errorText?: string;
  info?: string;
  required?: boolean;
  id?: string;
  fullWidth?: boolean;
  labelremark?: string;
}

const InputTemplate: FC<IInputTemplateProps> = ({
  label,
  className,
  error,
  errorText,
  info,
  required,
  children,
  id,
  fullWidth = false,
  labelremark,
}) => {
  return (
    <StylesProvider injectFirst>
      <InputWrapper className={className} id={id} fullWidth={fullWidth}>
        <LabelWrapper>
          {required && <RequiredLabel className="RequiredLabel">*</RequiredLabel>}
          {label && <InputLabel className="InputLabel">{label}</InputLabel>}
          {labelremark && <InputLabelRemark className="InputLabel">{labelremark}</InputLabelRemark>}
        </LabelWrapper>
        {children}
        <InputError className="InputError">{error}</InputError>
        <InputErrorText className="InputErrorText">{errorText}</InputErrorText>
        {info && <InputInfo>{info}</InputInfo>}
      </InputWrapper>
    </StylesProvider>
  );
};

export default InputTemplate;
