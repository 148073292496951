import { faPowerOff, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileImage from 'assets/images/user.svg';
import { Tooltip } from 'components/ui';
import { ConfirmMessage } from 'components/ui/Alert';
import { AlertStatus } from 'components/ui/enum/alert.enum';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from 'shared/config/app.env';
import constants from 'shared/constants';
import { IReducer } from 'store';
import { getUserDetailSso, logout, logoutSso } from 'store/authenticate';
import { getRolePermission, getUserInfo, IUser } from 'store/user';
import { getUserProfile } from 'store/user-profile/user-profile.action';
import styled from 'styled-components';
import ClearCache from './ClearCache';
// import Notification from './Notification';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { reactLocalStorage } from 'reactjs-localstorage';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const AppNameWrapper = styled.div`
  padding-top: 5px;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  @media only screen and (max-width: 767px) {
    /* For tablet: */
    display: none;
  }
`;

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    /* For tablet: */
    margin-left: auto;
  }
`;

const UserProfileInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    /* For tablet: */
    display: none;
  }
`;

const HeaderText = styled.div`
  color: white;
  margin: 0;
  font-size: 18px;
  user-select: none;
  height: 1em;
`;

const UserNameText = styled(HeaderText)`
  font-size: 20px;
  font-weight: bold;
`;

const UserText = styled(HeaderText)`
  font-size: 16px;
  flex: 3;
  margin: 3px;
  width: 70px;
`;

const UserLabel = styled(UserText)`
  flex: 1;
  font-weight: bold;
  margin: 3px;
`;

const UserImage = styled.img`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 10px;
  cursor: pointer;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #6d6d77;
  height: 50px;
`;

const LogoutButton = styled(FontAwesomeIcon)`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

const HeaderHighlightText = styled(HeaderText)`
  color: #f7b801;
`;

const TextRow = styled.div`
  display: flex;
`;

export const AppINCName: FC = () => {
  const { t } = useTranslation('main');
  return (
    <AppNameWrapper>
      <HeaderText>{'INC'}</HeaderText>
      <HeaderText>{'Investigation of Narcotics and Crime'}</HeaderText>
      <HeaderHighlightText>{t('header.nacrotic_suppression.label')}</HeaderHighlightText>
    </AppNameWrapper>
  );
};

export const HeaderPortalINC: FC = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const user: IUser = useSelector((store: IReducer) => store.user.me);
  const history = useHistory();
  const isAuthenticate = useSelector((store: IReducer) => store.authenticate.isAuthenticate);
  const userSso = useSelector((store: IReducer) => store.authenticate.userDetailSso);
    var is_window = navigator.userAgent.indexOf('Windows') > -1;

  useEffect(() => {
    // if (isAuthenticate) dispatch(getUserInfo());
    // if (isAuthenticate) dispatch(getUserProfile());
    if (config.SSO && isAuthenticate) dispatch(getUserDetailSso());
    if (config.SSO && !!!localStorage.getItem('token-sso')) {
      reactLocalStorage.clear();
      window.location.reload();
    }
  }, [dispatch]);

  useEffect(() => {
    // if (isAuthenticate && user?.roleID) dispatch(getRolePermission(user?.roleID));
    // dispatch(getRolePermission(2))
  }, [user?.roleID]);

  const onLogout = () => {
    if (is_window) {
      reactLocalStorage.clear();
      window.close();
    } else {
      window.location.href = config.BASE_URL;
    }
    return;
    /**
     ConfirmMessage(
      t('logout.logout_modal.header.label'),
      AlertStatus.Warning,
      t('logout.logout_modal.description.label')
    ).then((isConfirm) => {
      if (isConfirm) {
        if (config.SSO) dispatch(logoutSso());
        else dispatch(logout(onLogoutSuccess));
        // reactLocalStorage.clear();
        // caches.keys().then((names) => {
          //   names.forEach((name) => {
            //     caches.delete(name);
            //   });
            // });
          }
        });
    */
  };

  return (
    <UserProfileWrapper>
      {/* NOTE: CONFIGURE CLEAR CACHE */}
      {window.location.href.includes('localhost') && <ClearCache />}
      <UserProfileInfoWrapper>
        <UserNameText>{`${userSso?.idCard || '-'}`}</UserNameText>
        <TextRow>
          <UserLabel>{t('logout.name.label')}</UserLabel>
          <Tooltip baseText={userSso?.fullname || '-'} title={userSso?.fullname || '-'} />
        </TextRow>
      </UserProfileInfoWrapper>
      <VerticalDivider />
      <LogoutButton icon={faUndoAlt as IconProp} color={'#fff'} size={'2x'} onClick={onLogout} />
      {/* <LogoutButton icon={faPowerOff as IconProp} color={'#fff'} size={'2x'} onClick={onLogout} /> */}
    </UserProfileWrapper>
  );
};
