import { TableBody as Body, TableCell, TableRow } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  CloudDownloadOutlined as DownloadIcon,
  Edit as EditIcon,
  InsertDriveFile as FileIcon,
  ShareOutlined as GraphIcon,
  BackupOutlined as UploadIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import React, { FC } from 'react';
import constants from 'shared/constants';
import styled from 'styled-components';
import { LargeText } from '..';
import { ETableOrder } from '../enum';
import { TTableOrder } from '../type';
import TableContext from './TableContext';

const TableBodyRow = styled(TableRow)`
  border-bottom: 2px solid ${constants.LIGHT_BLUE};
`;
const TableBodyColumn = styled(TableCell)`
  width: ${(props) => props.width};
`;
const TableBodyActionColumn = styled(TableBodyColumn)`
  width: 140px;
`;
const TableBodyText = styled(LargeText)<{ maxwidth?: string }>`
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : '200ch')};
  margin: 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TableActionWrapper = styled.div`
  margin-left: auto;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, max-content);

  > svg {
    cursor: pointer;
  }

  > div {
    width: 1em;
    height: 1em;
  }
`;

const comparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) return -1;
  else if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'asc' ? (a, b) => comparator(a, b, orderBy) : (a, b) => -comparator(a, b, orderBy);
};

const stableOrder = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const onSpacing = (spaceCount?: number) => {
  if (spaceCount) return <div></div>;
};

const TableBody: FC = () => (
  <TableContext.Consumer>
    {({
      columns,
      items,
      order,
      orderBy,
      page,
      itemsPerPage,
      spaceCount,
      onGraph,
      onHeadline,
      onDownload,
      onUpload,
      onFile,
      onView,
      onEdit,
      onDelete,
      onDeleteModal,
      onDownloadFileByType,
    }) => (
      <Body>
        {stableOrder(items, getComparator(order, orderBy)).map((item: any) => {
          // const isItemSelected = selectedItems.includes(item.id);
          return (
            <TableBodyRow
              key={item.id}
              // isselected={isItemSelected}
            >
              {/* <TableBodyColumn padding={'checkbox'}>
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => onSelect(event, item.id)}
                  />
                </TableBodyColumn> */}
              {columns.map((column) => (
                <TableBodyColumn key={column.key} align={column.align} width={column.width}>
                  <TableBodyText maxwidth={column.width}>{item[column.key]}</TableBodyText>
                </TableBodyColumn>
              ))}
              {(onView ||
                onEdit ||
                onDelete ||
                onGraph ||
                onDownload ||
                onFile ||
                onUpload ||
                onDeleteModal ||
                onHeadline ||
                onDownloadFileByType) && (
                <TableBodyActionColumn>
                  <TableActionWrapper>
                    {!item.isShow && onSpacing(spaceCount)}
                    {onGraph && item.isShow && <GraphIcon onClick={() => onGraph(item.id)} />}
                    {onHeadline && item.isShow && <GraphIcon onClick={() => onHeadline(item)} />}
                    {onView && <VisibilityIcon onClick={() => onView(item.id)} />}
                    {onEdit && <EditIcon onClick={() => onEdit(item.id)} />}
                    {onDownloadFileByType && <DownloadIcon onClick={() => onDownloadFileByType(item)} />}
                    {onUpload && <UploadIcon onClick={() => onUpload(item.id)} />}
                    {onDownload && <DownloadIcon onClick={() => onDownload(item.filePath)} />}
                    {onFile && <FileIcon onClick={() => onFile(item.id)} />}
                    {onDelete && <DeleteIcon onClick={() => onDelete(item.id)} />}
                    {onDeleteModal && <DeleteIcon onClick={() => onDeleteModal(item)} />}
                  </TableActionWrapper>
                </TableBodyActionColumn>
              )}
            </TableBodyRow>
          );
        })}
      </Body>
    )}
  </TableContext.Consumer>
);

export default TableBody;
