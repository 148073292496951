import Logo from 'assets/images/inc-icon-new.png';
import _ from 'lodash';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import constants from 'shared/constants';
import styled from 'styled-components';
import { AppName, UserProfile } from './HeaderComponents';
import { AppINCName, HeaderPortalINC } from './HeaderPortalINC';
import config from 'shared/config/app.env';
import { reactLocalStorage } from 'reactjs-localstorage';
const HEADER_SIZE = 70;
const HEADER_WIDTH = 75;
const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div<{ linkage: boolean }>`
  /* display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_SIZE}px;
  padding-left: ${HEADER_WIDTH}px;
  z-index: 5; */
  display: flex;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
  position: fixed;
  padding-left: ${(props) => (props.linkage ? '0px' : `${HEADER_WIDTH}px`)};
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${110}px;
  height: ${83}px;
  padding: 15px;
  margin-top: -5px;
  cursor: pointer; /* This changes the cursor to a pointer when hovering */
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Authenticated: FC = () => {
  const history = useHistory();
  const isLinkage = _.get(history, 'location.pathname').includes('linkage');
  var is_window = navigator.userAgent.indexOf('Windows') > -1;
  const onLogout = () => {
    if (is_window) {
      reactLocalStorage.clear();
      window.close();
    } else {
      window.location.href = config.BASE_URL;
    }
    return;
    /**
     ConfirmMessage(
      t('logout.logout_modal.header.label'),
      AlertStatus.Warning,
      t('logout.logout_modal.description.label')
    ).then((isConfirm) => {
      if (isConfirm) {
        if (config.SSO) dispatch(logoutSso());
        else dispatch(logout(onLogoutSuccess));
        // reactLocalStorage.clear();
        // caches.keys().then((names) => {
          //   names.forEach((name) => {
            //     caches.delete(name);
            //   });
            // });
          }
        });
    */
  };
  
  return (
    <HeaderWrapper linkage={isLinkage}>
      {isLinkage ? (
        <>
          {' '}
          <AppIcon src={Logo} onClick={onLogout}/>
          <Wrapper>
            <AppINCName />
            <HeaderPortalINC />
          </Wrapper>
        </>
      ) : (
        <Wrapper>
          {' '}
          <AppName />
          <UserProfile />
        </Wrapper>
      )}
    </HeaderWrapper>
  );
};

export default Authenticated;
